.config-table td.rt-td, .item-details-table td.rt-td, .item-groups-table td.rt-td, .params-group-table td.rt-td, .items-in-group-table {
    text-align: center;
}

.column-status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vh;
}

.column-status-wrapper-diod {
    width: 12px;
    height: 45px;
    border: 1px solid gray;
}

.column-status-wrapper-diod-active {
    background-color: #00ffcc;
}

.column-status-wrapper-diod-inactive {
    background-color: red;
}

.make-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 900 !important;
    color: crimson !important;
}

return-person-table .logs-table .rt-th:nth-child(1), .logs-table .rt-td:nth-child(1) {
    min-width: 10%;
}

.logs-table .rt-th:nth-child(2), .logs-table .rt-td:nth-child(2) {
    min-width: 70%;

}

.logs-table .rt-tbody {
    min-width: 365px;
}

.sale-table .rt-th:nth-child(1), .sale-table .rt-td:nth-child(1) {
    min-width: 165px;
}

.sale-table .rt-th:nth-child(2), .sale-table .rt-td:nth-child(2) {
    min-width: 200px;
}

.sale-table .rt-tbody {
    min-width: 365px;
}

.details-table .rt-th:nth-child(1), .details-table .rt-td:nth-child(1) {
    min-width: 175px;

}

.details-table .rt-th:nth-child(2), .details-table .rt-td:nth-child(2) {
    min-width: 100px;

}

.details-table .rt-th:nth-child(3), .details-table .rt-td:nth-child(3) {
    min-width: 100px;

}

.details-table .rt-th:nth-child(4), .details-table .rt-td:nth-child(4) {
    min-width: 100px;

}

.details-table .rt-th:nth-child(5), .details-table .rt-td:nth-child(5) {
    min-width: 200px;

}

.details-table .rt-tbody {
    min-width: 675px;
}

/* .devices-table .rt-th, .devices-table td.rt-td {
    min-width: 275px;
}

.devices-table .rt-th:last-child, .devices-table .rt-td:last-child {
    min-width: 275px;
}

.devices-table td.rt-td {
    min-width: 275px;
}

.devices-table td.rt-td:last-child {
    min-width: 275px;
} */

.devices-table .rt-th:nth-child(1), .devices-table .rt-td:nth-child(1) {
    max-width: 220px;
    min-width: 220px;

}

.devices-table .rt-th:nth-child(2), .devices-table .rt-td:nth-child(2) {
    max-width: 100px;

}

.devices-table .rt-th:nth-child(3), .devices-table .rt-td:nth-child(3) {
    max-width: 100px;

}

.devices-table .rt-th:nth-child(4), .devices-table .rt-td:nth-child(4) {
    max-width: 250px;

}

.devices-table .rt-th:nth-child(5), .devices-table .rt-td:nth-child(5) {
    max-width: 100px;

}
.devices-table .rt-th:nth-child(6), .devices-table .rt-td:nth-child(6) {
    max-width: 100px;

}
.devices-table .rt-th:nth-child(7), .devices-table .rt-td:nth-child(7) {
    max-width: 100px;

}
.devices-table .rt-th:nth-child(8), .devices-table .rt-td:nth-child(8) {
    max-width: 100px;

}
.devices-table .rt-th:nth-child(9), .devices-table .rt-td:nth-child(9) {
    max-width: 100px;

}
.devices-table .rt-th:nth-child(10), .devices-table .rt-td:nth-child(10) {
    max-width: 100px;

}
.devices-table .rt-th:last-child, .devices-table .rt-td:last-child {
    min-width: 275px;
}

.devices-table .rt-tbody , .devices-table .rt-thead {
    min-width: 840px;
}

/* .devices-table .rt-th, .devices-table td.rt-td {
    min-width: 275px;
}

.devices-table .rt-th:last-child, .devices-table .rt-td:last-child {
    min-width: 275px;
}

.devices-table td.rt-td {
    min-width: 275px;
}

.devices-table td.rt-td:last-child {
    min-width: 275px;
} */

.devices-table .rt-tbody , .devices-table .rt-thead {
    min-width: fit-content;
}

.item-details-table .rt-th:nth-child(1), .item-details-table .rt-td:nth-child(1) {
    min-width: 85px;

}

.item-details-table .rt-th:nth-child(2), .item-details-table .rt-td:nth-child(2) {
    min-width: 100px;

}

.item-details-table .rt-th:nth-child(3), .item-details-table .rt-td:nth-child(3) {
    min-width: 150px;

}

.item-details-table .rt-th:nth-child(4), .item-details-table .rt-td:nth-child(4) {
    min-width: 150px;

}

.item-details-table .rt-th:nth-child(5), .item-details-table .rt-td:nth-child(5) {
    min-width: 150px;

}

.item-details-table .rt-th:nth-child(6), .item-details-table .rt-td:nth-child(6) {
    min-width: 165px;

}

.item-details-table .rt-th:nth-child(7), .item-details-table .rt-td:nth-child(7) {
    min-width: 150px;

}

.item-details-table .rt-tbody {
    min-width: 950px;
}

.item-groups-table .rt-th:nth-child(1), .item-groups-table .rt-td:nth-child(1) {
    min-width: 175px;

}

.item-groups-table .rt-th:nth-child(2), .item-groups-table .rt-td:nth-child(2) {
    min-width: 275px;
}

.item-groups-table .rt-tbody {
    min-width: 450px;
}

.params-group-table .rt-th:nth-child(1), .params-group-table .rt-td:nth-child(1) {
    min-width: 175px;

}

.params-group-table .rt-th:nth-child(2), .params-group-table .rt-td:nth-child(2) {
    min-width: 275px;
}

.params-group-table .rt-tbody {
    min-width: 450px;
}

.users-table .rt-th:nth-child(1), .users-table .rt-td:nth-child(1) {
    min-width: 175px;

}

.users-table .rt-th:nth-child(2), .users-table .rt-td:nth-child(2) {
    min-width: 175px;

}

.users-table .rt-th:nth-child(3), .users-table .rt-td:nth-child(3) {
    min-width: 175px;

}

.users-table .rt-th:nth-child(4), .users-table .rt-td:nth-child(4) {
    min-width: 100px;

}

.users-table .rt-th:nth-child(5), .users-table .rt-td:nth-child(5) {
    min-width: 175px;

}

.users-table .rt-th:nth-child(6), .users-table .rt-td:nth-child(6) {
    min-width: 275px;

}

.users-table .rt-th:nth-child(7), .users-table .rt-td:nth-child(7) {
    min-width: 275px;

}

/* .users-table .rt-tbody {
    min-width: 1075px;
} */

.users-table .rt-tbody {
    min-width: fit-content;
}



.items-in-group-table .rt-th, .item-groups-table td.rt-td {
    min-width: 275px;
}

.items-in-group-table .rt-th:last-child, .item-groups-table .rt-td:last-child {
    min-width: 275px;
}

.items-in-group-table td.rt-td {
    min-width: 275px;
}

.items-in-group-table td.rt-td:last-child {
    min-width: 275px;
}

.items-in-group-table .rt-tbody {
    min-width: fit-content;
}

.config-table .rt-th, .return-person-table .rt-th, .return-machine-table .rt-th {
    min-width: 275px;
}

.config-table .rt-th:last-child, .return-person-table .rt-th:last-child, .return-machine-table .rt-th:last-child {
    min-width: 275px;
}

.config-table td.rt-td, .return-person-table td.rt-td, .return-machine-table td.rt-td {
    min-width: 275px;
}

.config-table td.rt-td:last-child, .return-person-table td.rt-td:last-child, .return-machine-table td.rt-td:last-child {
    min-width: 275px;
}

.config-table .rt-tbody, .return-person-table .rt-tbody, .return-machine-table .rt-tbody, .insights-table .rt-tbody {
    min-width: fit-content;
}

 .insights-table .rt-th {
    min-width: 205px;
}

 .insights-table .rt-th:last-child {
    min-width: 205px;
}

.insights-table td.rt-td {
    min-width: 205px;
}

.insights-table .rt-th:last-child {
    display: none;
}

.insights-table td.rt-td:last-child {
    display: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.hide-col {
    display: none;
}

@media (max-width: 600px) {
    .devices-table .rt-th:last-child, .devices-table .rt-td:last-child {
        min-width: 75px;
    }
    .devices-table .rt-th:nth-child(1), .devices-table .rt-td:nth-child(1) {
        max-width: 220px;
    
    }
    .devices-table .rt-tbody , .devices-table .rt-thead {
        min-width: 600px;
    }
}