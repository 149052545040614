.sidebar .sidebar-wrapper {
    overflow-x: hidden;
}

.breadcrumb {
    background-color: transparent !important;
    justify-content: flex-end;
}


.react-calendar__tile--rangeStart {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.react-calendar__tile--rangeEnd {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.react-daterange-picker__clear-button svg, .react-daterange-picker__calendar-button svg {
    stroke: white;
}

.react-daterange-picker__wrapper {
    display: block;
    width: 100%;
    height: calc(2.249999625rem + 2px);
    padding: 0.5rem 0.7rem;
    font-weight: 400;
    line-height: 1.428571;
    color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-color: #2b3553 !important;
    border-radius: 0.4285rem;
    font-size: 0.7500000025rem;
}

.react-daterange-picker__inputGroup__input {
    color: white;
}

.form-logs-input-date, .form-sale-input-date {
    width: 100%;
}

.form-logs-input-date .react-daterange-picker__inputGroup:first-of-type, .form-sale-input-date .react-daterange-picker__inputGroup:first-of-type {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form-logs-input-date .react-daterange-picker__inputGroup:last-of-type, .form-sale-input-date .react-daterange-picker__inputGroup:last-of-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.submit-button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.textarea-form-control {
    resize: vertical !important;
    max-height: none !important;
    min-height: 150px;
    max-height: 500px !important;
}

.textarea-form-control::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.textarea-form-control::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.textarea-form-control::-webkit-scrollbar-thumb {
    background: #8C8C8D;
    border-radius: 10px;
}

/* Handle on hover */
.textarea-form-control::-webkit-scrollbar-thumb:hover {
    background: #4d4c4c;
    width: 10px;
}

.select-option-form-control {
    color: black;
}

.google-maps-wrapper {
    overscroll-behavior: contain;

}

.google-maps-wrapper #map {
    border-radius: 5px;
}

.constant-form {
    color: white !important;
}




.form-logs-input-date .react-daterange-picker__inputGroup__year, .form-sale-input-date .react-daterange-picker__inputGroup__year {
    /* width: 35px !important; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-size: 14px; */
}

.form-logs-input-date .react-daterange-picker__inputGroup__month, .form-sale-input-date .react-daterange-picker__inputGroup__month {
    /* width: 20px !important; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-size: 14px; */
}

.form-logs-input-date .react-daterange-picker__inputGroup__day, .form-sale-input-date .react-daterange-picker__inputGroup__day {
    /* width: 20px !important; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-size: 14px; */
}


.date-submit-center {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-el {
    display: flex;
}

.flex-el-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-el-bottom-center {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex-el-bottom-left {
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: 0.3em;
    margin-bottom: 0.8em;
}

.form-control {
    height: calc(2.249999625rem + 3px);
}

.login-eye .input-group-text {
    border-color: rgba(29, 37, 59, 0.2) !important;
}

.custom-form-control-color {
    padding: 0.15rem 0.7rem;
}


.item-details-breadcrumbItem+.item-details-breadcrumbItem::before, .group-conf-breadcrumbItem+.group-conf-breadcrumbItem::before {
    float: left;
    padding-right: 0.5rem;
    color: #ffffff;
    content: ">" !important;
}

.country-list .country-name {
    color: black
}

.react-tel-input .form-control {
    width: inherit !important;
    background: #26293d !important;
    border: 1px solid #2b3553 !important;
}

.react-tel-input .flag-dropdown {
    background: #26293d !important;
    border: 1px solid #2b3553 !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #26293d !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #26293d !important;
}

.react-tel-input .country-list {
    border-radius: 10px !important;
}

.spinner-together {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.modal-back {
    background-color: #26293d;
}

.modal-color {
    color: white;
}

.required-border {
    border: 1px solid red;
    border-radius: 5px;
    pad: 3px;
}

.modal-higher-close {
    top: 17px !important;
}

.title-down {
    margin-bottom: 5px !important;
}

.title-white {
    color: white !important;
}

.select-has-danger {
    border-color: #f33620;
    color: #ec250d !important;
    background-color: rgba(222, 222, 222, 0.1);
    border: 1px solid;
    border-radius: 4px;
}

.label-has-danger {
    color: red !important;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bell-dropdown {
    display: flex;
    align-items: center;
}

.bell-serial-name {
    font-weight: bold;
}

.bell-logs-photo {
    display: inline-flex;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;
    margin-right: 10px;
    min-width: 35px;

}

.bell-wrap-text {
    white-space: break-spaces;
}

.bell-logs-photo-tim-icons {
    margin-right: 10px;
    position: relative;
    top: 15px !important;
    left: 10px !important;
    font-size: 15px !important;
    margin-top: -5px;
    opacity: 0.5;
    font-weight: 900;
    color: white;
}

.nav_bell__pd_left {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.bell-nav-item-div-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dropdown-bell {
    max-height: 200px;
    overflow-y: scroll;
}

.dropdown-bell::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dropdown-bell::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dropdown-bell::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dropdown-bell::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-bell_min_width {
    min-width: 450px;
}

.custom-bell-notification {
    height: auto;
    width: max-content;
    min-width: 20px;
    left: 35px;
    border-radius: 0.125rem;
    top: 5px;
    border: 0;
    padding: 0 1px;
    font-size: 10px;
}

.animated-bell {
    animation: bell-ring 5s infinite;
    position: relative;
}

@keyframes bell-ring {
    0% {
        transform: translate(1px, 0px) rotate(0deg);
    }

    5% {
        transform: translate(1px, 0px) rotate(-15deg);
    }

    10% {
        transform: translate(1px, 0px) rotate(15deg);
    }

    15% {
        transform: translate(1px, 0px) rotate(-10deg);
    }

    20% {
        transform: translate(1px, 0px) rotate(10deg);
    }

    25% {
        transform: translate(1px, 0px) rotate(0deg);
    }

    100% {
        transform: translate(1px, 0px) rotate(0deg);
    }
}

.label-ghost-effect {
    animation: fade 3s infinite;
    user-select: none;
}

.label-has-success {
    color: #00ffcc !important;
}

.card-chart .chart-area {
    height: max-content !important;
}

.radio-has-danger {
    border-color: #f33620;
    color: #ec250d !important;
    background-color: rgba(222, 222, 222, 0.1);
    border: 1px solid;
    border-radius: 4px;
}

.radio-error {
    color: red !important;
}

.radio-success {
    color: green !important;
}

.custom-select {
    color: #1d8cf8;
    text-transform: capitalize;
}

.custom-select option {
    color: black; /* Text color for dropdown options */
}

.custom-select:focus {
    color: #1d8cf8;
}

.custom-select option:checked {
    color: #1d8cf8; /* Ensure selected options retain the custom color */
}
