.login-page .content {
    display: flex;
    justify-content: center;
}

.main_bg_video_wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content #video_bg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.card-login .login-paragraph {
    color: red;
    font-size: 10px;
    text-transform: uppercase;
    height: 25px;
}

.login_form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    position: relative;
    padding: 0 1.5rem 1.5rem;
    position: relative;
    z-index: 3;
    max-width: 400px;
    margin: 0 1rem;
}

.login_form_title {
    color: black;
    width: 100%;
    padding-top: 3vh;
    /* text-align: left; */
}

.custom-main-card {
    border-top: 10px solid #ECF200;
    border-bottom: 10px solid #434242;
    border-radius: 10px;
}

.login_form_container>div img {
    margin: 0 auto;
    /* border-radius: 50%; */
}
.form_container_img {
    padding-bottom: 3vh;
}


.login_form_container>div>a {
    position: relative;
    /* top: -46px; */
    width: 50%;
    border-radius: 50%;
    color: rgb(92, 188, 226);
    
}

.login_form_container img {
    display: block;
    width: 50%;
    /* box-shadow: 0px 0px 39px 22px rgb(18, 18, 32); */
}

.auth_form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.auth_form_footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
}

.auth_form_footer a {
    color: black;
    /* color: #002faf; */
}